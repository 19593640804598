// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app\\\\root.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app\\root.tsx");
}
// REMIX HMR END

import { Separator } from "@/components/ui/separator";
import { cssBundleHref } from "@remix-run/css-bundle";
import { Link, Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData } from "@remix-run/react";
import clsx from "clsx";
import { PreventFlashOnWrongTheme, ThemeProvider, useTheme } from "remix-themes";
import { ModeToggle } from "./components/ModeToggle";
import { themeSessionResolver } from "./sessions.server";
import styles from "./tailwind.css";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/remix";
import { Button } from "./components/ui/button";
import { Github } from "lucide-react";
export const links = () => [{
  rel: "stylesheet",
  href: styles
}, ...(cssBundleHref ? [{
  rel: "stylesheet",
  href: cssBundleHref
}] : [])];

// Return the theme from the session storage using the loader
export async function loader({
  request
}) {
  const {
    getTheme
  } = await themeSessionResolver(request);
  return {
    theme: getTheme()
  };
}
export default function AppWithProviders() {
  _s();
  const data = useLoaderData();
  return <ThemeProvider specifiedTheme={data.theme} themeAction="/action/set-theme">

            <App />
        </ThemeProvider>;
}
_s(AppWithProviders, "5thj+e1edPyRpKif1JmVRC6KArE=", false, function () {
  return [useLoaderData];
});
_c = AppWithProviders;
function App() {
  _s2();
  const data = useLoaderData();
  const [theme] = useTheme();
  return <html lang="en" className={clsx(theme)}>
            <head>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />

                <Meta />
                <PreventFlashOnWrongTheme ssrTheme={Boolean(data.theme)} />
                <Links />
            </head>
            <body>
                <main className="mx-auto flex min-h-dvh max-w-sm flex-col items-center justify-between px-4">
                    <Outlet />
                    <div className="flex w-full flex-col items-center justify-between gap-1 pt-8">
                        <Separator />
                        <div className="flex w-full flex-row items-center justify-between pb-4">
                            <Link to={`/`}>
                                <span className="text-m scroll-m-20 font-semibold tracking-tight">
                                    WAYF
                                </span>
                            </Link>
                            <div className="flex flex-row items-center">
                                <Button variant="ghost" size="icon" asChild>
                                    <a href="https://github.com/runandrew/remix-wayf" target="_blank" rel="noopener noreferrer">

                                        <Github className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:rotate-0 dark:scale-100" />
                                    </a>
                                </Button>
                                <ModeToggle />
                            </div>
                        </div>
                    </div>
                </main>
                <ScrollRestoration />
                <Scripts />
                <LiveReload />
                <Analytics />
                <SpeedInsights />
            </body>
        </html>;
}
_s2(App, "JtPbXBRliJCZ1bjeSVJ5KStBUfQ=", false, function () {
  return [useLoaderData, useTheme];
});
_c2 = App;
var _c, _c2;
$RefreshReg$(_c, "AppWithProviders");
$RefreshReg$(_c2, "App");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;